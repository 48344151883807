<template>
  <div class="w-e-text-container">
    <div v-html="widget.options.html" data-slate-editor></div>
  </div>
</template>

<script>
export default {
  props: {
    /** @type {import("../../models/widget").default} */
    widget: Object
  }
};
</script>

<style lang="less" scoped>
@import "~@wangeditor/editor/dist/css/style.css";
@import "./overwrite.less";

.w-e-text-container {
  height: auto;
  // 用于创建 block context，防止外边距合并效果导致尺寸检测不准确
  overflow: hidden;
  .overwrite_editor();
  /deep/ hr {
    margin: 40px 20px;
    border: none;
    height: 1px;
  }
  /deep/ h1 {
    font-size: 2em;
  }
  /deep/ *:last-child {
    margin-bottom: 0 !important;
  }
}
</style>
